import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import get from 'lodash/get'
import map from 'lodash/map'
import Cta from 'components/cta'

// import Adsense from 'components/adsense'

import './style.scss'

const Post = ({ data, options }) => {
  const {
    category,
    tags,
    description,
    title,
    path,
    date,
    image,
  } = data.frontmatter
  // const { isIndex, adsense } = options
  const { isIndex } = options
  const html = get(data, 'html')
  const isMore = isIndex && !!html.match('<!--more-->')
  const fluid = get(image, 'childImageSharp.fluid')

  return (
    <article className="article" key={path}>
      <div className="mw7 mx-auto px-3 px-md-0">
        <div className="info text-justify">
          <h1>{title}</h1>
          <time dateTime={date}>{date}</time>
          {Badges({ items: [category], primary: true })}
          {Badges({ items: tags })}
        </div>
        <p className="text-justify desc">{description}</p>
      </div>
      <div className="mw7 mx-auto">
        {fluid ? (
          <Img
            fluid={fluid}
            style={{ display: 'block', margin: '1.6rem auto' }}
          />
        ) : (
          ''
        )}
      </div>
      <div className="container mw7 px-md-5">
        <aside className="card my-4 bg-light">
          <div className="card-body text-justify text-md-center">
            <p className="h3">遺品整理の業者探しにお困りではないですか？</p>
            <p className="card-text mb-0">
              eイーセイリは、あなたの地域の優品整理業者を手間なく最安値の業者を探せます。
            </p>
            <p className="card-text font-weight-bold">
              最大5社のお見積もりから、1社をお選びいただき、他社のお断りを代行します。
            </p>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <a href="/lp/" className="btn btn-primary w-100 py-2">
                  <span className="h4 font-weight-bold c-dotted">
                    優良で最安値の業者を探す
                  </span>
                  <br />
                  <small>手数料無料／お見積もり無料／お断り歓迎</small>
                </a>
              </div>
            </div>
          </div>
        </aside>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: isMore ? getDescription(html) : html,
          }}
        />
        {isMore ? Button({ path, label: 'MORE', primary: true }) : ''}
        {/* {getAd(isIndex, adsense)} */}
      </div>
    </article>
  )
}

export default Post

// const getAd = (isIndex, adsense) => {
//   return !isIndex ? <Adsense clientId={adsense} slotId="" format="auto" /> : ''
// }

// const getDescription = (body) => {
//   body = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
//   if (body.match('<!--more-->')) {
//     body = body.split('<!--more-->')
//     if (typeof body[0] !== 'undefined') {
//       return body[0]
//     }
//   }
//   return body
// }

// const Button = ({ path, label, primary }) => (
//   <Link className="readmore" to={path}>
//     <span
//       className={`btn btn-outline-primary btn-block ${
//         primary ? 'btn-outline-primary' : 'btn-outline-secondary'
//       }`}
//     >
//       {label}
//     </span>
//   </Link>
// )

const Badges = ({ items, primary }) =>
  map(items, (item, i) => {
    return (
      <span
        className={`badge ${primary ? 'badge-primary' : 'badge-secondary'}`}
        key={i}
      >
        {item}
      </span>
    )
  })
